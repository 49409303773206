<template>
	<Header></Header>
    <div class="Create">
		<h1>铸造新作品</h1>
		<div class="drawerTitle"><font color=red>*</font>作品名称</div>
		<el-input v-model="form.pnName" placeholder="请输入作品名称" />
		<div class="drawerTitle"><font color=red>*</font>图片、视频、音频</div>
		<div style="font-size:14px;margin-bottom:10px;">文件类型支持:GLTF,GLB,WEBM,MP4,MOV,M4V,OGV,OGG,MP3,WAV,OGA,JPG,PNG,JPEG,GIF,SVG。最大大小:100 MB</div>
		<el-upload
		    class="avatar-uploader"
		    :action="uploadUrl"
		    :show-file-list="false"
		    :on-success="successUpload"
		    :before-upload="beforeAvatarUpload"
			:headers="headers"
		  >
		    <img v-if="type == 0" :src="form.pnFile" class="avatar" />
			<div v-else-if="type == 1">
				<video
					style="width:350px;"
					controls="" 
					controlslist="nodownload" 
					loop="" 
					preload="auto" 
					:src="form.pnFile" 
				>
					您的浏览器不支持 video 标签。
				</video>
				<div class="change">上传</div>
			</div>
			<div v-else-if="type == 2">
				<audio :src="form.pnFile" controls="">
					您的浏览器不支持 audio 标签。
				</audio>
				<div class="change">上传</div>
			</div>
		    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
		</el-upload>
		<div v-if="type == 1 || type == 2 ">
			<div class="drawerTitle"><font color=red>*</font>封面图</div>
			<el-upload
			    class="avatar-uploader"
			    :action="uploadUrl"
			    :show-file-list="false"
			    :on-success="successUploadCover"
			    :before-upload="beforeAvatarUploadCover"
				:headers="headers"
			  >
			    <img v-if="form.pnImage != ''" :src="form.pnImage" class="avatar" />
			    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
			</el-upload>
		</div>
		<div class="drawerTitle">描述</div>
		<el-input v-model="form.pnDes" show-word-limit maxlength="500" :rows="4" type="textarea" placeholder="请输入描述" />
		<div class="drawerTitle"><font color=red>*</font>专辑</div>
		<div>
			<el-select :disabled="disabled" popper-class="elSelect" v-model="form.cnName" filterable placeholder="请选择专辑" style="width:100%" @change="selectBtn">
			    <el-option
			      v-for="item in rows"
			      :key="item.cnId"
			      :label="item.cnName"
			      :value="item"
			    >
					<div>
						<img class="elOptionImg" :src="item.cnLogo">{{item.cnName}}
					</div>
				</el-option>
			</el-select>
		</div>
		<div class="drawerTitle"><font color=red>*</font>数量</div>
		<div style="font-size:14px;margin-bottom:10px;">
			可铸造的作品数量。
		</div>
		<el-select :disabled="disabled" v-model="form.pctId" placeholder="请选择数量" style="width:100%">
		    <el-option
		      v-for="item in rowsCount"
		      :key="item.pctId"
		      :label="item.pctCount"
		      :value="item.pctId"
		    />
		</el-select>
		<div class="drawerTitle"><font color=red>*</font>是否实物</div>
		<div style="font-size:14px;margin-bottom:10px;">
			如果选择是，代表该作品属于实际物品，买家下单后，您需要快递该物品至买家提供的收货地址。
		</div>
		<el-select v-model="form.pnMatter" placeholder="请选择" style="width:100%">
			<el-option label="是" key="1" value="1"/>
			<el-option label="否" key="0" value="0"/>
		</el-select>
		<div class="drawerTitle">外部链接地址</div>
		<div style="font-size:14px;margin-bottom:10px;">
			将在这个项目的详细页面上包括一个到这个URL的链接，这样用户可以点击来了解更多。欢迎您将更多详情链接到您自己的网页。
		</div>
		<el-input v-model="form.pnExternal" placeholder="请填写外部链接地址" />
		<div class="drawerTitle"><font color=red>*</font>版税（最大值不超过10%）</div>
		<div style="font-size:14px;margin-bottom:10px;">
			卖家在平台售卖您的作品时，将按照该比例将销售额的部分金额打入您的账户。
		</div>
		<el-input v-model="form.pnTax" type="number" :min="0" :max="10" placeholder="请输入版税" @input="changeBs">
		    <template #append>%</template>
		</el-input>
		<div class="drawerTitle">附加属性</div>
		<div>
			<template v-for="(item,index) in JSONArr" :key="index">
				<div class="inputBox">
					<span>
						<el-input v-model="item.key" placeholder="属性" @input="changeValue(index)"/>
					</span>
					<span>
						<el-input v-model="item.value" placeholder="属性值" @input="changeValue(index)"/>
					</span>
				</div>
			</template>
		</div>
		<div class="buttom">
			 <el-button type="primary" :loading="loadingBtn" @click="createBtn">保存</el-button>
		</div>
    </div>
	<el-dialog
	    width="300px"
		title="提示"
	    v-model="dialogVisible"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
		custom-class="dialogSty"
	>	
		<div style="width:252px;height:252px;">
			<div style="color:red;font-weight:bold;margin:5px 0px;">系统发现已有相同的作品被铸造</div>
			<div class="card-carousel--card">
				<div class="card-carousel-box">
					<img :src="pnImage" style="object-fit: cover;"/>
				</div>
				<div class="card-carousel--card--footer">
					<div class="name">
						{{pnName}}
					</div>
					<div class="Id">
						<span style="font-weight:bold;">Token Id:</span>{{pnTokenId}}
					</div>
					<div class="Id">
						<span>作者：{{pnAuthorName}}</span>
					</div>
				</div>
			</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">取消</el-button>
	        <el-button type="primary" @click="reportBtn">去举报</el-button>
	      </span>
	    </template>
	</el-dialog>
	<el-dialog
	    width="600px"
		title="提示"
		top="7vh"
	    v-model="dialogReport"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
	>
		<div style="height:550px;overflow-y:auto;">
			<div style="margin:15px 0px;">举报原因</div>
			<div v-for="(item,index) in reasonRows" :key="index">
				<el-radio v-model="trnId" :label="item.trnId" size="large" @change="changeTrnId(item)">{{item.trnReason}}</el-radio>
			</div>
			<div style="margin:15px 0px;">举报说明</div>
			<el-input v-model="tsDes" show-word-limit maxlength="200" :rows="4" type="textarea" placeholder="请输入描述" />
			<div style="margin:15px 0px;">举报图片</div>
			<el-upload
			    list-type="picture-card"
				:limit="3"
			    :action="uploadUrl"
				:headers="headers"
			    :on-success="successUploadReason"
			    :before-upload="beforeAvatarUploadReasonr"
			    :on-remove="handleRemove"
			    :file-list="fileList"
			>
			    <el-icon><Plus /></el-icon>
			</el-upload>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogReport = false">取消</el-button>
	        <el-button type="primary" :loading="loadingReason" @click="reasonBtn">举报</el-button>
	      </span>
	    </template>
	</el-dialog>
	<Footer></Footer>
</template>
<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import { Plus } from '@element-plus/icons-vue'
	import { ElLoading } from 'element-plus'
	export default {
		inject: ["reload"],
		components:{ Header,Footer,Plus },
		data(){
			return{
				uploadUrl:this.$uploadUrl +'common/oss/uploadTemp',
				headers:{
					token: window.localStorage.getItem("token")
				},
				dialogReport:false,
				disabled:false,
				loadingBtn:false,
				loadingReason:false,
				dialogVisible:false,
				type:null,
				loading:null,
				rows:[],
				rowsCount:[],
				reasonRows:[],
				fileList:[],
				imgArr:[],
				JSONArr:[
					{
						key:'',
						value:''
					}
				],
				tsDes:'',
				trnId:'',
				pnName:'',
				pnImage:'',
				pnAuthor:'',
				pnOwner:'',
				pnImage:'',
				pnId:'',
				pnOwnerName:'',
				pnTokenId:'',
				pnAuthorName:'',
				trnReason:'',
				form:{
					pnId:'',
					pnName:'',
					pnImage:'',
					pnFile:'',
					pnDes:'',
					pnProperties:'',
					pnMatter:'0',
					pnExternal:'',
					pnTax:'0',
					pctId:'',
					cnId:'',
					cnName:'',
					cteId:'',
					cteName:'',
					urId:window.localStorage.getItem("urId")
				},
				player:null
			}
		},
		watch:{
			'$route': function (to, from) {
				let pnId = this.$route.params.pnId;
				if(pnId != '123'){
					this.disabled = true;
					this.getObject(pnId);
				}else{
					this.fileList=[];
					this.imgArr=[];
					this.type=null;
					this.JSONArr=[
						{
							key:'',
							value:''
						}
					],
					this.form={
						pnId:'',
						pnName:'',
						pnImage:'',
						pnFile:'',
						pnDes:'',
						pnProperties:'',
						pnMatter:'0',
						pnExternal:'',
						pnTax:'0',
						pctId:'',
						cnId:'',
						cnName:'',
						cteId:'',
						cteName:'',
						urId:window.localStorage.getItem("urId")
					}
					this.player=null;
					this.disabled = false;
				}
				this.initData();
		　　}
		},
		created(){
			let pnId = this.$route.params.pnId;
			if(pnId != '123'){
				this.disabled = true;
				this.getObject(pnId);
			}else{
				this.disabled = false;
			}
			this.initData();
		},
		methods:{
			reasonBtn(){
				let that = this;
				let tsDes = that.tsDes;
				let pnOwner = that.pnOwner;
				let pnOwnerName = that.pnOwnerName;
				let trnId = that.trnId;
				let pnAuthor = that.pnAuthor;
				if(trnId == ""){
					that.$message.error("请选择填写举报原因!");
					return false;
				}
				if(tsDes == ""){
					that.$message.error("请填写举报说明!");
					return false;
				}
				if(that.imgArr.length == 0){
					that.$message.error("请上传举报图片!");
					return false;
				}
				that.loadingReason = true;
				this.$axios({
					method:'post',
					url:'nft/ts/save',
					headers:{
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						tsId:'',
						tsDes: tsDes,
						pnId: that.pnId,
						pnImage: that.pnImage,
						pnAuthor: that.pnAuthor,
						pnAuthorName: that.pnAuthorName,
						pnOwner: that.pnOwner,
						pnOwnerName: that.pnOwnerName,
						cnId: that.form.cnId,
						cnName: that.form.cnName,
						trnId: that.trnId,
						trnReason: that.trnReason,
						proofs:that.imgArr.join(","),
						urId: window.localStorage.getItem("urId"),
						urName: window.localStorage.getItem("urName")
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("举报成功!");
						that.dialogReport = false;
						that.dialogVisible = false;
					}else{
						that.$message.error(msg);
					}
					that.loadingReason = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			handleRemove(uploadFile, uploadFiles){
				let url = uploadFile.response.path
				let index = this.imgArr.indexOf(url)
				this.imgArr.splice(index,1);
			},
			successUploadReason(res,file){
				this.imgArr.push(res.path);
			},
			beforeAvatarUploadReasonr(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 100;
				if (!isJPG) {
					this.$message.error('请上传正确的图片格式!');
				}
				if (!isLt5M) {
				  this.$message.error('上传图片大小不能超过100MB!');
				}
				return isJPG && isLt5M;
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
			},
			changeTrnId(val){
				this.trnId = val.trnId;
				this.trnReason = val.trnReason;
			},
			reportBtn(){
				let that = this;
				this.$axios({
					method:'post',
					url:'nft/trn/getAll',
					headers:{
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({})
				})
				.then((res)=>{
					that.reasonRows = res.data.dataRoot;
					that.dialogReport = true;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			createBtn(){
				let that = this;
				let pnName = that.form.pnName;
				let pctId = that.form.pctId;
				let pnMatter = that.form.pnMatter;
				let cnId = that.form.cnId;
				let pnTax = that.form.pnTax;
				let pnFile = that.form.pnFile;
				let pnExternal = that.form.pnExternal;
				let pnImage = that.form.pnImage;
				let type = that.type;
				if(pnName == ''){
					that.$message.error("请填写作品名称!");
					return false;
				}
				if(pnFile == ''){
					that.$message.error("请上传作品文件");
					return false;
				}
				if(type == '1' || type == '2' ){
					if(pnImage == ""){
						that.$message.error("请上传封面图");
						return false;
					}
				}
				if(cnId == ''){
					that.$message.error("请选择专辑");
					return false;
				}
				if(pctId == ''){
					that.$message.error("请选择数量!");
					return false;
				}
				if(pnMatter == ''){
					that.$message.error("请选择是否实物");
					return false;
				}
				if(pnTax == ''){
					that.$message.error("请填写版税");
					return false;
				}
				let pnId = this.$route.params.pnId;
				if(pnId != ''){
					that.confirmBtn();
				}else{
					that.$confirm('确定要消耗一次燃料来铸造作品吗？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.confirmBtn();
					})
					.catch(() => {});
				}
				
			},
			confirmBtn(){
				let that = this;
				let url = "nft/pn/save";
				if(that.form.pnId != ""){
					url = "nft/pn/update"
				}
				that.form.pnProperties = JSON.stringify(that.JSONArr);
				that.loadingBtn = true;
				this.$axios({
					method:'post',
					url:url,
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify(that.form)
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.type = null;
						that.$message.success("保存成功!");
						that.$router.push({
							name:'Main.User',
							query:{
								type:3
							}
						})
					}else if(msg == 0){
						this.$confirm('您的燃料剩余0，充值后再进行铸造!', '提示', {
							confirmButtonText: '去充值',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {
							
						})
						.catch(() => {});
					}else if(msg == "-1"){
						that.pnName = res.data.pnName;
						that.pnImage = res.data.pnImage;
						that.pnAuthorName = res.data.pnAuthorName;
						that.pnTokenId = res.data.pnTokenId;
						that.pnAuthor = res.data.pnAuthor;
						that.pnOwner = res.data.pnOwner;
						that.pnOwnerName = res.data.pnOwnerName
						that.pnId = res.data.pnId;
						that.pnImage = res.data.pnImage;
						that.dialogVisible = true;
					}else{
						that.$message.error(msg);
					}
					that.loadingBtn = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			changeValue(index){
				let key = this.JSONArr[index].key;
				let value = this.JSONArr[index].value;
				let length = this.JSONArr.length;
				if(value != '' && key != ""){
					if(index+1 == length){
						this.JSONArr.push({
							key:'',
							value:''
						})
					}
				}
				if(value == '' && key == ""){
					if(index+1 != length){
						this.JSONArr.splice(index,1);
					}
				}
			},
			changeBs(val){
				if(Number(val) > 10){
					this.form.pnTax = 10;
				}
				if(Number(val) < 0){
					this.form.pnTax = 0;
				}
			},
			selectBtn(val){
				let cnId = val.cnId;
				let cnName = val.cnName;
				let cteId = val.cteId;
				let cteName = val.cteName;
				this.form.cnId = cnId;
				this.form.cnName = cnName;
				this.form.cteId = cteId;
				this.form.cteName = cteName;
			},
			beforeAvatarUpload(file){
				const isJPG = file.type === 'video/mov' || file.type === 'video/quicktime' || file.type === 'video/mp4' || file.type === 'audio/oga' || file.type === 'audio/wav' || file.type === 'audio/mp3' ||  file.type === 'video/ogg' ||  file.type === 'video/ogv' || file.type === 'video/m4v' ||  file.type === 'video/mp4' ||  file.type === 'video/webm' || file.type === 'video/glb' || file.type === 'video/gltf' || file.type === 'image/svg' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 100;
				if (!isJPG) {
					this.$message.error('请上传正确的文件格式!');
					this.loading.close();
					return false;
				}
				if (!isLt5M) {
					this.$message.error('上传文件大小不能超过 100MB!');
					this.loading.close();
					return false;
				}
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
			},
			successUpload(res,file){
				let suffix = res.suffix;
				if(suffix == ".svg" || suffix == ".png" || suffix == ".jpeg" || suffix == ".jpg" || suffix == ".gif"){
					this.form.pnImage = '';
					this.type = 0;
				}
				if(suffix == ".mov" || suffix == ".mp4" || suffix == ".ogg" ||suffix == ".gltf" ||suffix == ".glb" ||suffix == ".webm" ||suffix == ".mp4" ||suffix == ".m4v" ||suffix == ".ogv"){
					this.type = 1;
				}
				if(suffix == ".mp3" || suffix == ".wav" || suffix == ".OGA" || suffix == ".oga" ){
					this.type = 2;
				}
				this.form.pnFile = res.path;
				this.loading.close();
			},
			beforeAvatarUploadCover(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 100;
				if (!isJPG) {
					this.$message.error('请上传正确的图片格式!');
					this.loading.close();
					return false;
				}
				if (!isLt5M) {
				  this.$message.error('上传图片大小不能超过100MB!');
				  this.loading.close();
				  return false;
				}
				return isJPG && isLt5M;
				this.loading = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
			},
			successUploadCover(res,file){
				this.form.pnImage = res.path;
				this.loading.close();
			},
			getObject(pnId){
				this.$axios({
					method:'post',
					url:'nft/pn/getObject',
					headers:{
						token: window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						pnId:pnId
					})
				})
				.then((res)=>{
					let that = this;
					let suffix = res.data.data.pnFileType;
					let pnProperties = res.data.data.pnProperties;
					that.form = res.data.data;
					that.JSONArr = JSON.parse(pnProperties); 
					if(suffix == ".svg" || suffix == ".png" || suffix == ".jpeg" || suffix == ".jpg" || suffix == ".gif"){
						this.form.pnImage = '';
						this.type = 0;
					}
					if(suffix == ".mov" || suffix == ".mp4" || suffix == ".OGG" ||suffix == ".GLTF" ||suffix == ".GLB" ||suffix == ".WEBM" ||suffix == ".MP4" ||suffix == ".M4V" ||suffix == ".OGV"){
						this.type = 1;
					}
					if(suffix == ".mp3" || suffix == ".wav" || suffix == ".WAV" || suffix == ".OGA" || suffix == ".oga" ){
						this.type = 2;
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			initData(){
				this.$axios({
					method:'post',
					url:'nft/cn/getMine',
					headers:{
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId:window.localStorage.getItem("urId"),
						page:1,
						limit:10000
					})
				})
				.then((res)=>{
					this.rows = res.data.dataRoot;
				})
				.catch((err)=>console.log('请求失败',err))
				this.$axios({
					method:'post',
					url:'nft/pot/getAll',
					headers:{
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						page:1,
						limit:10000
					})
				})
				.then((res)=>{
					this.rowsCount = res.data.dataRoot;
					if(res.data.dataRoot.length>0){
						this.form.pctId = res.data.dataRoot[0].pctId;
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
		}
	};
</script>
<style scoped>
	.Create{
		width: 800px;
		padding: 24px;
		margin: 0px auto;
		box-sizing: border-box;
	}
	.Create h1{
		font-weight: 600;
		font-size: 40px;
		letter-spacing: 0px;
		color: rgb(4, 17, 29);
		margin-bottom: 16px;
		margin-top:55px;
	}
	.elOptionImg{
		width:30px;
		height:30px;
		vertical-align: middle;
		margin-right:10px;
		border-radius: 50%;
	}
	#video{
		width:350px;
		height:270px;
	}
	.inputBox span{
		width:368px;
		margin-bottom:15px;
		display: inline-block;
		vertical-align: text-top;
	}
	.inputBox span:first-child{
		margin-right:16px;
	}
	.drawerTitle{
		margin-bottom: 10px;
		margin-top: 10px;
		font-weight: bold;
	}
	.el-icon.avatar-uploader-icon{
		width:350px;
		height:270px;
	}
	.buttom{
		width:752px;
	}
	.buttom .el-button{
		width:752px;
		height:40px;
	}
	.elSelect .selected{
		height:60px;
		line-height: 60px;
		border-bottom:1px solid #e0e2e4;
	}
	.elSelect .selected:last-child{
		border-bottom:none;
	}
	.el-upload .change{
		text-align: center;
		padding:10px 0px;
	}
	.el-upload .change:hover{
		background:#d9d9d9;
	}
</style>
